import {Injectable} from '@angular/core';
import {environment} from '../../../../../../environments';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {
  CustomerDetails,
  PfCustomerDetailsModel,
  PfCustomersContentModel
} from '../../../_modules/portfolio/_model/portfolio-customers.model';
import {DeleteCheck, PortfolioCustomerCreationRequest, PortfolioCustomerUpdate} from '../../_model/customer-v4.model';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {getParams} from '../../_zen-legacy-common/_utils/set-param-util';
import {DataFlowHierarchyGroupedV4Model} from '../../_model/data-flow-hierarchy-v4.model';
import {BulkImportV4Model, ZenBulkImportResponse, ZenBulkImportsListV4Model} from '../../_model/bulk-import-v4.model';
import {ZenHierarchicalGrouping} from '../../_enums/zen-hierarchical-grouping.enum';
import {PageableModel} from '../../../_modules/portfolio/_model/pageable.model';
import {ActivationChangeBulkModel} from '../../_model/activation-change-bulk.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerServiceV4 {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, private orgMgtSvc: OrganizationManagementService) {
  }

  createCustomer(creationRequest: PortfolioCustomerCreationRequest): Observable<CustomerDetails> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers`;
    return this.http.post<any>(url, {...creationRequest}).pipe(take(1));
  }

  updateCustomer(customerId: number, customerUpdates: PortfolioCustomerUpdate): Observable<CustomerDetails> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}`;
    return this.http.put<CustomerDetails>(url, {...customerUpdates}).pipe(take(1));
  }

  getCustomerDetails(customerId: number): Observable<CustomerDetails> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}`;
    return this.http.get<CustomerDetails>(url).pipe(take(1));
  }

  bulkDeleteCheckCustomers(customerIds: number[]): Observable<DeleteCheck> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/bulk/delete-check`;
    return this.http.post<DeleteCheck>(url, {ids: customerIds}).pipe(take(1));
  }

  /**
   * Bulk delete customers - Soft delete is currently the only thing a non-admin user can do.
   * Payload -> {ids: number[]; deleteMode = DeleteMode.soft;} -> where applicable (customers)
   * @param customerIds
   */
  bulkDeleteCustomers(customerIds: number[]) {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/bulk/delete`;
    return this.http.post(url, {ids: customerIds}).pipe(take(1));
  }

  /**
   * Bulk change status customers - Deactivate or reactivate customers using the table or row action toggles
   * Payload -> {ids: number[]} -> where applicable (customers)
   * @param customerIds
   * @param activationBulkChange
   */
  bulkChangeStatusCustomers(customerIds: number[], activationBulkChange: ActivationChangeBulkModel) {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/customers/bulk/activation`;
    return this.http.put(url, activationBulkChange).pipe(take(1));
  }

  /**
   * Bulk import LEN, ServiceAddress, Meter, Contract, Bill data for a single customer
   * @param customerId
   * @param file
   * @param commodityType
   * @param importData
   */
  bulkImportV4(customerId: number, file: File, commodityType: CommodityType, importData: boolean, allowDrafts: boolean): Observable<BulkImportV4Model> {
    // Attach file as a multipart form data
    const formData = new FormData();
    formData.append('excelFile', file);
    const params = getParams({
      importData,
      commodityType,
      allowDrafts
    });
    let url;
    if (commodityType === CommodityType.Electricity) {
      url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/electric-bulk-import`;
    } else {
      url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/natgas-bulk-import`;
    }
    return this.http.post<BulkImportV4Model>(url, formData, {params: params}).pipe(take(1));
  }

  /**
   * This method used to handle bulk import preview data (stage data upload) call.
   * The purpose of this API to restrict uploading data w.r.t the RC state (onlyStateAllowed).
   * @param customerId
   * @param rateCheckId
   * @param file
   * @param commodityType
   */
  bulkImportV4RateCheckFlow(customerId: number, rateCheckId: string, file: File, commodityType: CommodityType): Observable<BulkImportV4Model> {
    // Attach file as a multipart form data
    const formData = new FormData();
    formData.append('excelFile', file);

    const params = getParams({commodityType});

    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/rate-checks/${rateCheckId}/bulk-import`;
    return this.http.post<BulkImportV4Model>(url, formData, {params: params}).pipe(take(1));
  }


  getCustomerBulkImports(customerId: number, state: string, commodityType: CommodityType, page?: number, size?: number, sortBy?: string, sortDir?: string): Observable<PageableModel<ZenBulkImportsListV4Model>> {
    let params = getParams({
      state, commodityType, page, size, sort: (sortBy && sortDir) ? `${sortBy},${sortDir}` : undefined
    });
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/bulk-imports`;
    return this.http.get<PageableModel<ZenBulkImportsListV4Model>>(url, {params}).pipe(take(1));
  }

  getImportGroupedDataById(customerId: number, bulkImportId: string, groupBy = ZenHierarchicalGrouping.CONTRACT): Observable<DataFlowHierarchyGroupedV4Model[]> {
    const params = getParams({groupBy});
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/bulk-import/${bulkImportId}/grouped`;
    return this.http.get<DataFlowHierarchyGroupedV4Model[]>(url, {params}).pipe(take(1));
  }

  getRecentlyImportedData(customerId: number, groupBy = ZenHierarchicalGrouping.CONTRACT, commodityType: CommodityType, bulkImportId?: string): Observable<DataFlowHierarchyGroupedV4Model[]> {
    const params = getParams({groupBy, commodityType});
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/bulk-import/recent-meters`;
    return this.http.get<DataFlowHierarchyGroupedV4Model[]>(url, {params}).pipe(take(1));
  }

  persistImportDataById(customerId: number, bulkImportId: string, allowDrafts: boolean): Observable<BulkImportV4Model> {
    const params = getParams({allowDrafts});
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/bulk-import/${bulkImportId}/persist`;
    return this.http.post<BulkImportV4Model>(url, {}, {params}).pipe(take(1));
  }

  /**
   * Used to bulk-delete imported data rows.
   * @param customerId
   * @param bulkImportId
   * @param rowIds
   */
  bulkDeleteRowsByIds(customerId: number, bulkImportId: string, rowIds: number[]): Observable<ZenBulkImportResponse> {
    let url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/bulk-import/${bulkImportId}/bulk-delete`;
    return this.http.post<ZenBulkImportResponse>(url, {ids: rowIds}).pipe(take(1));
  }

  deleteByBulkImportId(customerId: number, bulkImportId: string): Observable<void> {
    let url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/bulk-import/${bulkImportId}`;
    return this.http.delete<void>(url).pipe(take(1));
  }

  /**
   * Used to update individual row of imported data
   * @param rowId
   * @param customerId
   * @param bulkImportId
   * @param commodityType
   * @param _req
   */
  updateImportRowDataById(rowId: number, customerId: number, bulkImportId: string, _req): Observable<DataFlowHierarchyGroupedV4Model[]> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/bulk-import/${bulkImportId}/row/${rowId}/grouped`;
    return this.http.put<DataFlowHierarchyGroupedV4Model[]>(url, _req).pipe(take(1));
  }

}
